import { motion } from "framer-motion";
import { SocialIcon } from "react-social-icons";
import styled from "styled-components";

type Props = {
  url?: string;
};

const Animate = styled(motion.div)``;

export const SocialMedia = ({ url }: Props) => (
  <Animate whileHover={{ scale: 1.2 }}>
    <SocialIcon url={url} bgColor={"white"} />
  </Animate>
);
