import { SocialMedia } from "./SocialMedia";
import styled from "styled-components";
import { motion } from "framer-motion";

const socialMediaLinks = [
  "https://www.linkedin.com/in/brian-emmett-9b1486112",
  "https://twitter.com/BrianEEmmett",
  "https://github.com/brianemmettjnr",
];

const SocialMediaContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
`;

const PrinterIcon = styled.h2`
  width: fit-content;
  a:visited {
    color: #ffffff;
  }
  a:hover {
    color: #000000;
  }
`;

const Animate = styled(motion.div)`
  justify-content: center;
  text-align: center;
`;
const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Home = () => {
  return (
    <HomeContainer>
      <h2>Hi.</h2>
      <h2>I'm Brian.</h2>
      <h2>I'm a Software Engineer.</h2>
      <PrinterIcon color="white">
        <Animate whileHover={{ scale: 1.2 }}>
          <a href="/printer">🖨️</a>
        </Animate>
      </PrinterIcon>
      <SocialMediaContainer>
        {socialMediaLinks.map((url) => {
          return <SocialMedia url={url} />;
        })}
      </SocialMediaContainer>
    </HomeContainer>
  );
};

export default Home;
