import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Resizer from "react-image-file-resizer";
import { motion } from "framer-motion";
import "./Printer.css";
import API from "./API";
import { Message } from "./API/generated/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const TextBox = styled.input`
  flex: 1;
  font-family: "MyFont";
  font-size: 18px;
  padding: 20px;
  margin: 20px;
  background: papayawhip;
  border: none;
  border-radius: 3px;
  ::placeholder {
    color: palevioletred;
  }
`;

const PrinterSubject = styled.input`
  font-variant-ligatures: none;
  flex: 1;
  font-family: VT323;
  font-size: 5vh;
  width: 100%;
  padding: 0;
  background: white;
  border-radius: 3px 3px 0px 0px;
  resize: none;
  border: none;
  outline: none;
  overflow: hidden;
  text-align: center;
`;

const PrinterPage = styled.textarea`
  font-variant-ligatures: none;
  flex: 1;
  margin-top: -1px;
  font-size: 3.5vh;
  padding: 0;
  font-family: VT323;
  width: 100%;
  background: white;
  word-wrap: break-word;
  word-break: break-all;
  border-radius: 0px 0px 3px 3px;
  resize: none;
  outline: none;
  border: none;
  overflow: hidden;
  text-align: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Label = styled.h2`
  width: 40%;
  text-align: left;
`;

const Animate = styled(motion.div)`
  justify-content: center;
  text-align: center;
`;

const SubmissionButton = styled.input`
  cursor: pointer;
  outline: 0;
  color: #000;
  background-color: #ffffff;
  border-color: #0d6efd;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 16px;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

const Printer = () => {
  const [text, setText] = useState("");
  const [from, setFrom] = useState("");
  const [printed, setPrinted] = useState(false);
  const [printerOnline, setPrinterOnline] = useState(false);
  const [file, setFile] = useState<string>("");
  const [printText, setPrintText] = useState<boolean>(true);

  async function handleChange(e: any) {
    console.log(e.target.files[0]);
    let image = await resizeFile(e.target.files[0]);
    console.log(image);
  }

  const resizeFile = (file: Blob) =>
    new Promise<Blob>(() => {
      var img = document.createElement('img');
      var blob = URL.createObjectURL(file);
      img.src = blob;
      img.onload = function () {
        console.log(img.height);
        if (img.width > img.height) {
          Resizer.imageFileResizer(
            file,
            5000,
            500,
            "JPEG",
            100,
            90,
            (uri) => {
              console.log(uri);
              if (uri instanceof Blob) {
                setFile(URL.createObjectURL(uri));
              }
            },
            "blob"
          );
        } else {
          Resizer.imageFileResizer(
            file,
            500,
            5000,
            "JPEG",
            100,
            0,
            (uri) => {
              console.log(uri);
              if (uri instanceof Blob) {
                setFile(URL.createObjectURL(uri));
              }
            },
            "blob"
          );
        }
      }


    });

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (printText) {
      let res = API.Print.printSendMessagePost({ sender: from, text: text });
    } else {
      let blob = await fetch(file).then((r) => r.blob());
      let res = API.Print.printPrintImagePost(new File([blob], "name"));
    }
    setText("");
    setFile("");
    setFrom("");
    setPrinted(true);
  };

  useEffect(() => {
    const CheckOnline = async () => {
      let res = await API.Print.online();
      setPrinterOnline(res.status === 200);
    };
    CheckOnline();
  }, []);

  return (
    <>
      {printed ? <h1>Message Received!</h1> : null}
      <Row>
        <h1>💬 </h1>
        <label className="switch">
          <input
            type="checkbox"
            onChange={(e) => setPrintText(!e.currentTarget.checked)}
          />
          <span className="slider"></span>
        </label>
        <h1> 🖼️</h1>
      </Row>

      {printerOnline ? (
        printText ? (
          <Form onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "40vh",
              }}
            >
              <PrinterSubject
                maxLength={20}
                // wrap="soft"
                // rows={1}
                onKeyDown={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
                placeholder="Your Title Here"
                value={from}
                onChange={(e) => setFrom(e.target.value)}
              />
              <PrinterPage
                wrap="hard"
                rows={6}
                cols={27}
                placeholder="Your Message Here"
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
            </div>
            <Animate whileHover={{ scale: 1.2 }}>
              <button
                disabled={text.length === 0 || from.length === 0}
                type="submit"
                value="Submit"
                style={{ fontSize: "2.5vh" }}
              >
                🖨️
              </button>
            </Animate>
          </Form>
        ) : (
          <Form onSubmit={handleSubmit}>
            <h5>
              Below will be rough representation of the quality of the printed
              image. It will be slightly higher resolution than this but with
              more grain to it.
            </h5>
            <label className="custom-file-upload">
              <input type="file" onChange={handleChange} accept="image/*" />
              <FontAwesomeIcon icon={faUpload} />
            </label>
            <img
              style={{
                filter: "grayscale(100%) contrast(500%)",
                maxWidth: "75vw",
              }}
              src={file}
            />
            <Animate whileHover={{ scale: 1.2 }}>
              <button
                disabled={file.length === 0}
                type="submit"
                value="Submit"
                style={{ fontSize: "2.5vh" }}
              >
                🖨️
              </button>
            </Animate>
          </Form>
        )
      ) : (
        <h1>Apologies - The printer seems to be offline.</h1>
      )}
    </>
  );
};

export default Printer;
