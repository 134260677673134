import React, { useState } from "react";
import styled from "styled-components";
import no from './res/no.wav'
import badatgame from './res/badatgame.wav'
import bellend from './res/bellend.wav'
import deaf from './res/deaf.wav'
import easy from './res/easy.wav'
import fak from './res/fak.wav'
import noproblem from './res/noproblem.wav'
import serious from './res/serious.wav'
import shutup from './res/shutup.wav'
import yes from './res/yes.wav'
import yuppa from './res/yuppa.wav'

export interface AudioFile {
  name?: string;
  audio: HTMLAudioElement;
}

const audios: AudioFile[] = [
  {name: "No", audio: new Audio(no)},
  {name: "Bad At Game", audio: new Audio(badatgame)},
  {name: "Bellend", audio: new Audio(bellend)},
  {name: "Deaf", audio: new Audio(deaf)},
  {audio: new Audio()},
  {name: "Easy", audio: new Audio(easy)},
  {audio: new Audio()},
  {audio: new Audio()},
  {audio: new Audio()},
  {audio: new Audio()},
  {audio: new Audio()},
  {audio: new Audio()},
  {name: "Fak", audio: new Audio(fak)},
  {name: "No Problem", audio: new Audio(noproblem)},
  {name: "Serious", audio: new Audio(serious)},
  {name: "Shut Up", audio: new Audio(shutup)},
  {name: "Yes", audio: new Audio(yes)},
  {name: "Yuppa", audio: new Audio(yuppa)}
]

const Button = styled.button`
  width: 50%;
  height: 35%
`;

const Container = styled.div`
  background-image: url("/background.jpg") ;
  height: 484px;
  width: 400px;
  max-width: 100vw;

  display: grid;
  grid-template-columns: auto auto auto;

  justify-items: center;
  align-items: center;
`;

const JodBoard = () => {
  return <Container >
  {
       audios.map(audio => audio.name ? <> { <Button onClick={() => audio.audio.play()}>{audio.name}</Button>} </> : <><div></div></>)
    }
  </Container>
  ;
};

export default JodBoard;
