import styled from "styled-components";

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const GoalList = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: center;
`
const Goals = () => {
    return (
        <LayoutContainer>
            <h2>My Goals for 2023</h2>
            <GoalList>
                <li>Get a job.</li>
                <li>Get my driving license for a car.</li>
                <li>Visit 10 new countries.</li>
                <li>Run a marathon.</li>
                <li>Add more features to this website. Such as a Portfolio and Blog section, with a more advanced home page (Books?).</li>
                <li>Save 15,000.</li>
                <li>Maintain a weightlifting routine for the entire year.</li>
                <li>Read 12 books (~1 per month).</li>
                <li>Begin plans to emigrate in 2024.</li>
                <li>Reach 1000 FIDE rating in chess.</li>
                <li>Post at least one commit everyday for a year.</li>
            </GoalList>
        </LayoutContainer>
    );
};

export default Goals;
