import { Switch, Route } from "react-router-dom";
import styled from "styled-components";
import Home from "./Home";
import Printer from "./Printer";
import JodBoardPrivacyPolicy from "./JodBoardPrivacyPolicy";
import { motion } from "framer-motion";
import Goals from "./Goals";
import JodBoard from "./JodBoard";

const CoreStyle = styled.header`
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
`;

const Animation = styled(motion.div)``;

const Main = () => {
  return (
    <CoreStyle>
      <Animation
        initial={{ opacity: 0, scale: 0.01 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.6 }}
      >
        <Switch>
          <Route path="/jodboard-privacy-policy">
            <JodBoardPrivacyPolicy />
          </Route>
          <Route path="/printer">
            <Printer />
          </Route>
          <Route path="/goals">
            <Goals />
          </Route>
          <Route path="/jod">
            <JodBoard />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Animation>
    </CoreStyle>
  );
};
export default Main;
